import React from "react";
import { isFunction } from "lodash";

import { Redirect, Route, useLocation } from "react-router-dom";
// import { checkCookie } from '../utils/cookies';
import Sidebar from "../../components/Sidebar";

const PrivateRoute = ({ hideSidebar, component: Component, ...rest }) => {
  const location = useLocation();
  const userToken = localStorage.getItem("token");
  const route = (
    <Route
      {...rest}
      exact
      render={(props) => {
        return userToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
  return (isFunction(hideSidebar) ? hideSidebar({ location }) : hideSidebar) ? (
    route
  ) : (
    <Sidebar>{route}</Sidebar>
  );
};

export default PrivateRoute;
