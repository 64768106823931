import BaseService from "./BaseService";

import { getOptionsAsObject } from "../utils/LocalStorage";

const getUserDetails = () => {
  return getOptionsAsObject(`userProfile`);
};

const getUserId = () => {
  return getOptionsAsObject(`userProfile`).userID || "";
};

class Service extends BaseService {
  constructor() {
    super();
    this.userID = getOptionsAsObject(`userProfile`).userID;
  }
  getTeachers = () => {
    return this.request({
      url: `/meeting/all-teachers/${getUserId()}`,
      params: { userID: getUserId() },
    });
  };
  getUsersbyclassID = (classID) => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/meeting/users-by-class/${collegeID}/${classID}`,
    });
  };

  getAttendancebyUserID = () => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/meeting/get-attendance-by-user/${collegeID}/${getUserId()}`,
    });
  };

  getAttendanceofUserFromclassID = (classID) => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/meeting/get-attendance-of-user-from-classID/${collegeID}/${getUserId()}/${356}`,
    });
  };

  getClassesByTeacher = (teacherID) => {
    return this.request({
      url: `/meeting/all-classes-teacherID/${teacherID}`,
      params: { teacherID },
    });
  };

  getStudentByTeacher = (teacherID) => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/meeting/get-student-of-class-by-teacher/${collegeID}/${teacherID}`,
      params: { teacherID },
    });
  };
  saveMeeting = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/create_meeting`,
      body,
    });
  };

  authorize = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/authorize`,
      body,
    });
  };

  saveAttendance = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/create-attendance-user`,
      body,
    });
  };

  deleteMeeting = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/remove-meeting`,
      body: { ...body, created_by: getUserId() },
    });
  };

  cancelMeeting = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/cancel-meeting`,
      body: { ...body, created_by: getUserId() },
    });
  };
  deleteRecording = (id) => {
    return this.request({
      method: 'GET',
      url: `/meeting/delete-recording/${getUserId()}/${id}`,
    });
  };
  getMeetings = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/get-all-meeting`,
      body: { ...body, created_by: getUserId() },
    });
  };
  getJoinableMeetings = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/get-today-meeting`,
      body: { ...body, userID: getUserId() },
    });
  };
  getRecordings = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/get-recordings`,
      body: { ...body, userID: getUserId() },
    });
  };

  fetchClasses = () => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/Meeting/get-meetings-by-class/${collegeID}/${getUserId()}`,
    });
  };

  // http://localhost/ailabs/ailabbackend/index.php/Admin/fetch_college_classes/1

  saveServers = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/add-server-url`,
      body: { ...body, userID: getUserId() },
    });
  };
  getServers = () => {
    return this.request({
      url: `/meeting/get-all-server/${getUserId()}`,
    });
  };
  deleteServers = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/delete -server`,
      body: { ...body, userID: getUserId() },
    });
  };
  saveHosts = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/add-host`,
      body: { ...body, userID: getUserId() },
    });
  };
  getHosts = () => {
    return this.request({
      url: `/meeting/get-all-host/${getUserId()}`,
    });
  };
  deleteHosts = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/delete-host`,
      body: { ...body, userID: getUserId() },
    });
  };
  saveTeacherAccess = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/add-teacher-access`,
      body: { ...body, userID: getUserId() },
    });
  };
  getTeacherAccess = () => {
    return this.request({
      url: `/meeting/get-all-teacher-access/${getUserId()}`,
    });
  };
  deleteTeacherAccess = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/delete-teacher-access`,
      body: { ...body, userID: getUserId() },
    });
  };
  saveCreateParams = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/add-create-params`,
      body: { ...body, userID: getUserId() },
    });
  };
  getCreateParams = () => {
    return this.request({
      url: `/meeting/get-all-create-params/${getUserId()}`,
    });
  };
  deleteCreateParams = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/delete-create-params`,
      body: { ...body, userID: getUserId() },
    });
  };
  saveJoinParams = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/add-join-params`,
      body: { ...body, userID: getUserId() },
    });
  };
  getJoinParams = () => {
    return this.request({
      url: `/meeting/get-all-join-params/${getUserId()}`,
    });
  };
  getRoles = () => {
    return this.request({
      url: `/user/get-all-roles`,
    });
  };
  deleteJoinParams = (body) => {
    return this.request({
      method: 'POST',
      url: `/meeting/delete-join-params`,
      body: { ...body, userID: getUserId() },
    });
  };
  getMeetingLink = ({ host_id, meeting_auto_id }) => {
    return this.request({
      url: `/meeting/join-meeting/${host_id}/${getUserId()}/${meeting_auto_id}`,
    });
  };

  viewAttendanceByMeetingId = (classID, meetingID) => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/Meeting/get_manual_attendance_by_admin/${collegeID}/${classID}/${meetingID}`,
    });
  };

  getClassCountById = (classID) => {
return this.request({
      url: `/meeting/get-classcount?classID=${classID}`,
    });
  };
}
export default Service;
