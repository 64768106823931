import jwtdecode from "jwt-decode";

import { ACTIONS } from "../../actionTypes/index";
import axios from "axios"

import UserService from "../../../services/UserService";

const getMenuItems = (menus) => {
  const newMenus = menus.map((menu) => {
    const menuID = menu.menuID;
    let newMenu = {};
    newMenu["menuID"] = menuID;
    newMenu["title"] = menu.menuName;
    newMenu["url"] = `/${menu.menuURL == "#" ? "classes" : menu.menuURL}`;
    if (!!menu.submenu) {
      newMenu["subMenus"] = menu.submenu.map((subMenuItem) => {
        const menuID = subMenuItem.menuID;
        let newMenuModule = {};
        newMenuModule["menuID"] = menuID;
        newMenuModule["title"] = subMenuItem.menuName;
        newMenuModule["url"] = `${newMenu.url == "#" ? "classes" : newMenu.url
          }/${subMenuItem.menuURL}`;

        return newMenuModule;
      });
    }
    return newMenu;
  });

  return newMenus;
};

const {
  SET_USER_AUTHENTICATION,
  LOGIN_USER,
  SET_USER_AUTHENTICATION_ERROR,
  FORGOT_PASSWORD_WATCHER,
  SET_FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD_ERROR,
  USER_LOGOUT_WATCHER,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  VERIFICATION_CODE_WATCHER,
  VERIFICATION_CODE_SUCCESS,
  VERIFICATION_CODE_ERROR,
  RESET_PASSWORD_WATCHER,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_USER_PROFILE_BY_TOKEN_WATCHER,
  SET_USER_PROFILE_BY_TOKEN,
  SET_USER_PROFILE_BY_TOKEN_ERROR,
} = ACTIONS;

const service = new UserService();

export function loginUser(params) {
  return (dispatch) => {
    return service
      .login(params)
      .then((response) => {
        let userProfile = jwtdecode(response.token);

        userProfile = {
          ...userProfile,
          menus: getMenuItems(userProfile.menus_data),
        };
        localStorage.setItem("userProfile", JSON.stringify(userProfile));
        localStorage.setItem("token", response.token);
        
        dispatch({ type: LOGIN_USER, payload: response.data });
        return response;
      })
      .catch((e) => { });
  };
}

export function logOut(userId) {
  return (dispatch) => {
    return service
      .logout(userId)
      .then((response) => {
        dispatch({ type: USER_LOGOUT_SUCCESS, payload: response.data });
        return response;
      })
      .catch((e) => {
        dispatch({ type: USER_LOGOUT_ERROR, payload: e });
      });
  };
}
/* export const loginUser = (payload) => {
  service.login();
  //console.log("inside :loginUser:", payload);
  return { type: LOGIN_USER, payload };
};
 */
export const setUserAuthantication = (payload, resolve, reject) => {
  return { type: SET_USER_AUTHENTICATION, payload, resolve, reject };
};

export const setUserAuthanticationError = (payload, resolve, reject) => {
  return { type: SET_USER_AUTHENTICATION_ERROR, payload, resolve, reject };
};

export const forgotPasswordWatcher = (payload) => {
  return { type: FORGOT_PASSWORD_WATCHER, payload };
};
export const setforgotPassword = (payload) => {
  return { type: SET_FORGOT_PASSWORD, payload };
};

export const setforgotPasswordError = (payload) => {
  return { type: SET_FORGOT_PASSWORD_ERROR, payload };
};

export const userLogoutWatcher = (payload) => {
  return { type: USER_LOGOUT_WATCHER, payload };
};

export const userLogoutSuccess = (payload) => {
  return { type: USER_LOGOUT_SUCCESS, payload };
};

export const userLogoutError = (payload) => {
  return { type: USER_LOGOUT_ERROR, payload };
};

export const varificationCodeWatcherWatcher = (payload) => {
  return { type: VERIFICATION_CODE_WATCHER, payload };
};

export const varificationCodeSuccess = (payload) => {
  return { type: VERIFICATION_CODE_SUCCESS, payload };
};

export const varificationCodeError = (payload) => {
  return { type: VERIFICATION_CODE_ERROR, payload };
};

export const resetPasswordWatcher = (payload) => {
  return { type: RESET_PASSWORD_WATCHER, payload };
};

export const resetPasswordSuccess = (payload) => {
  return { type: RESET_PASSWORD_SUCCESS, payload };
};

export const resetPasswordError = (payload) => {
  return { type: RESET_PASSWORD_ERROR, payload };
};

/**
 * set user profile bu token
 *
 */

export const getUserPRofileByTokenWatcher = (payload) => {
  return { type: GET_USER_PROFILE_BY_TOKEN_WATCHER, payload };
};

export const setUserProfileByToken = (payload) => {
  return { type: SET_USER_PROFILE_BY_TOKEN, payload };
};

export const setUserProfileByTokenError = (payload) => {
  return { type: SET_USER_PROFILE_BY_TOKEN_ERROR, payload };
};
