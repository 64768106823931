import { merge, some, cloneDeep } from "lodash";

const USER_DETAILS_AND_TOKEN = ["token", "userProfile", "length"];

export const setOptionsAsObject = (key, value, mergeInSame = true) => {
  let item = localStorage.getItem(key);
  if (item && mergeInSame) {
    const newObj = merge(JSON.parse(item), cloneDeep(value));
    localStorage.setItem(key, JSON.stringify(newObj));
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }

  //console.log("loca", key, item);
};

export const getOptionsAsObject = (key, defaultValue = {}) => {
  if (key.includes("exercise")) {
    //console.log("localStorage.getItem(key)", localStorage.getItem(key));
  }
  return JSON.parse(localStorage.getItem(key)) || defaultValue;
};

export const setOptions = (key, value) => {
  localStorage.setItem(key, value);
};

export const getOptions = (key, defaultValue = "") => {
  return localStorage.getItem(key) || defaultValue;
};

export const removeKey = (key) => {
  localStorage.removeItem(key);
};

export const removeUserSettings = () => {
  for (let key in localStorage) {
    if (some(USER_DETAILS_AND_TOKEN, key)) {
      removeKey(key);
    }
  }
};

export const removeExerciseCodes = () => {
  for (let key in localStorage) {
    if (key.includes("exercise")) {
      removeKey(key);
    }
    if (key.includes("result")) {
      removeKey(key);
    }
    if (key.includes("linking")) {
      removeKey(key);
    }
  }
  removeKey("recordingDetails");
  removeKey("drawerDetails");
  removeKey("activeModuleId");
  removeKey("currentModule");
};
