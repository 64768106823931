import React, { useState, useEffect } from 'react';
import LiveClassesService from "../../services/LiveClassesService"
import axios from "axios"
import { Redirect } from 'react-router';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import config from "../../config";

const authorizeService = new LiveClassesService

function Authorize(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [redirectUrl, setRedirectUrl] = useState("")
    const response = localStorage.getItem("userProfile")
    let userProfile = JSON.parse(response)
    const { baseURL } = config;
    
    const url = props.location.search.replace(/\?/g, '');
    const data = url.split('&')

    // if (userProfile === null) {
    //     localStorage.setItem("redirect_uri", JSON.stringify(data[4].split('=').pop()));
    // }


    const variabl = {
        "response_type": data[0].split('=').pop(),
        "scope": data[1].split('=').pop(),
        "client_id": data[2].split('=').pop(),
        "state": data[3].split('=').pop(),
        "redirect_uri": data[4].split('=').pop(),
        "userProfile": userProfile
    }

    useEffect(() => {
        axios.post(baseURL + '/api/authorize', variabl)
            .then((res) => {
                setIsLoading(!isLoading)
                setRedirectUrl(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    return (

        <div>
            {

                isLoading ? window.location = redirectUrl : <div style={{ width: "5%", margin: "auto", marginTop: "55px" }}> <CircularProgress /></div>
            }

        </div>
    )

}

export default Authorize;