import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

// function saveToLocalStorage(state) {
//     try {
//         const serializedState=JSON.stringify(state)
//         localStorage.setItem('state',serializedState)
//     } catch (e) {
//         //console.log("saveToLocalStorage",e);

//     }

// }

// function loadFromLocalStorage(state) {
//     try {
//         const serializedState=localStorage.getItem('state')
//         if(serializedState===null)return undefined
//         return JSON.parse(serializedState)
//     } catch (e) {
//         //console.log("saveToLocalStorage",e);
//         return undefined

//     }

// }

// const persistedState= loadFromLocalStorage()
const store = createStore(
  rootReducer,
  // persistedState,
  composeEnhancer(applyMiddleware(thunk))
);
// store.subscribe(()=>saveToLocalStorage(store.getState()))

//sagaMiddleware.run(rootSaga);
export default store;
