import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import cn from 'classnames';
import { get as _get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link, useHistory, useLocation } from 'react-router-dom';

import { logOut } from '../../store/action/loginForm/login';

// import CollegeDropDown from '../../components/CollegeDropDown';
import { getOptionsAsObject } from '../../utils/LocalStorage';

//import { ReactComponent as Logo } from "../../svg_icons/clevered_green.png";
import Logo from '../../svg_icons/clevered_green.png';
import LogoWhite from '../../svg_icons/clevered_white.png';
import './Header.scss';

const isModuleSection = (path) => {
  return path.includes('lesson');
};
const Header = ({ logout, headerColor, currentModule }) => {
  const { timezone = 'Asia/Calcutta', first_name, last_name, userID, collegeLogo = false } = getOptionsAsObject('userProfile');
  const history = useHistory(),
    location = useLocation(),
    isModuleWindow = isModuleSection(location.pathname);

  const onLogout = () => {
    logout(userID);
    localStorage.clear();
    window.location.replace('/login');
  };

  const { lessonTitle = '', moduleTitle = '' } = currentModule;
  const user = getOptionsAsObject('userProfile');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header
      className={cn('Header', {
        'Header-Exercise': headerColor === 'green',
      })}
      //style={headerColor === "green" ? { position: "static" } : {}}
    >
      <Link className="Header-LogoWrapper" to="/dashboard">
        <img
          src={collegeLogo ? collegeLogo : isModuleWindow ? LogoWhite : Logo}
          // src={isModuleWindow ? LogoWhite : Logo}
          className="Header-Logo"
          href="/dashboard"
        />
        {/* <Logo className="Header-Logo" href="/dashboard" /> */}
      </Link>
      {/* <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" href="#"><i class="fas fa-bars"></i></button> */}
      {location.pathname == '/dashboard' ? null : <div className="header--text">{!isModuleWindow && `Welcome, ${first_name ? first_name : 'User'} ${last_name ? last_name : ''}`}</div>}

      {/* <div
        className='Names'
        style={{ paddingRight: '40%' }}
      >
        <div className='Contain'>
          <span className='Name-Lesson'>{lessonTitle}</span>
          <span className='Name-Module'>{moduleTitle}</span>
        </div>
      </div> */}

      <div className="right">
        {/* <CollegeDropDown /> */}
        <span className="header--text">{!isModuleWindow && `Time Zone: ${timezone}`}</span>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <AccountCircleIcon />
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleClose}>
            {' '}
            <a className="dropdown-item" href="/profile/view">
              My Profile
            </a>
          </MenuItem>

          {user.role == 3 && (
            <MenuItem onClick={handleClose}>
              <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_FRONT_END_DOMAIN}/profile/${user.username || ''}`}>
                View Public Profile
              </a>
            </MenuItem>
          )}

          <MenuItem onClick={handleClose}>
            <span className="dropdown-item has-icon" onClick={onLogout}>
              Logout
            </span>
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
};

function mapStateToProps(state, props) {
  return {
    currentModule: _get(state, 'module.currentModule', {}),
    headerColor: _get(state, 'course.headerColor', 'white'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logOut,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
