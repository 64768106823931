import { ACTIONS } from "../../actionTypes/index";

import ModuleService from "../../../services/ModuleService";
import { getOptionsAsObject } from "../../../utils/LocalStorage";

const {
  SET_MODULE,
  SET_MODULES,
  SET_MODULES_ERROR,
  SET_CURRENT_MODULE,
  SET_NEXT_MODULE,
  SET_PREV_MODULE,
  SET_ACTIVE_MODULE,
  SET_ACTIVE_MODULE_ID,
  SET_MODULES_LOADING,
  SET_USE_SOLUTION,
  SET_USE_SOLUTION_ERROR,
  SET_USE_HINT_ERROR,
  SET_USE_HINT,
  SET_USER_ACTIVITY,
  SET_USER_ACTIVITY_ERROR,
  SET_HEADER_COLOR,
} = ACTIONS;

const service = new ModuleService();

export function getAllModules(params, arrange = false) {
  const { changeHeader = true } = params;
  return (dispatch) => {
    dispatch({ type: SET_MODULES_LOADING });
    if (changeHeader) {
      dispatch({ type: SET_HEADER_COLOR, payload: "green" });
    }
    return service
      .getModules(params)
      .then((response) => {
        let payload = [];

        if (arrange) {
          payload = response.data.map((value, index) => ({
            ...value,
            module_display_order: `${index + 1}`,
          }));
        } else {
          payload = response.data;
        }

        payload = updateNonLinkedExercises(payload.slice());

        dispatch({ type: SET_MODULES, payload });
        return response;
      })
      .catch((e) => {
        dispatch({ type: SET_MODULES_ERROR, payload: e });
      });
  };
}

export function setCurrentModule(currentModule) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_MODULE, payload: currentModule });
  };
}

export function setActiveModule(moduleDetail, screen) {
  return (dispatch) => {
    return dispatch({ type: SET_ACTIVE_MODULE, payload: moduleDetail, screen });
  };
}
export function setModule(mId) {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_MODULE_ID, payload: mId });
    return dispatch({ type: SET_MODULE, payload: { mId } });
  };
}

export function setNextModule(moduleId) {
  return (dispatch) => {
    dispatch({
      type: SET_NEXT_MODULE,
      payload: moduleId,
    });
  };
}

export function setPrevModule(moduleId) {
  return (dispatch) => {
    return dispatch({ type: SET_PREV_MODULE, payload: moduleId });
  };
}

export function setModuleId(moduleId) {
  return (dispatch) => {
    return dispatch({ type: SET_ACTIVE_MODULE_ID, payload: moduleId });
  };
}

export function useSolution() {
  const {
      lessonId: lessonID,
      courseId: courseID,
      exerciseID,
    } = getOptionsAsObject("currentModule"),
    { userID } = getOptionsAsObject("userProfile"),
    body = {
      userID,
      courseID,
      lessonID,
      exerciseID,
    };
  return (dispatch) => {
    return service
      .useSolution(body)
      .then((response) => {
        dispatch({ type: SET_USE_SOLUTION, payload: true });
        return response;
      })
      .catch((e) => {
        dispatch({ type: SET_USE_SOLUTION_ERROR, payload: false });
      });
  };
}
export function useHint() {
  const {
      lessonId: lessonID,
      courseId: courseID,
      exerciseID,
    } = getOptionsAsObject("currentModule"),
    { userID } = getOptionsAsObject("userProfile"),
    body = {
      userID,
      courseID,
      lessonID,
      exerciseID,
    };
  return (dispatch) => {
    return service
      .useHint(body)
      .then((response) => {
        dispatch({ type: SET_USE_HINT, payload: true });
        return response;
      })
      .catch((e) => {
        dispatch({ type: SET_USE_HINT_ERROR, payload: false });
      });
  };
}

export function logUserActivity(cId, lId, mId) {
  const {
      lessonId: lessonID,
      courseId: courseID,
      exerciseID,
      moduleID,
    } = getOptionsAsObject("currentModule"),
    { userID } = getOptionsAsObject("userProfile"),
    body = {
      userID,
      courseID: cId || courseID,
      lessonID: lId || lessonID,
      exerciseID,
      moduleID: mId || moduleID,
    };
  return (dispatch) => {
    return service
      .logUserActivity(body)
      .then((response) => {
        dispatch({ type: SET_USER_ACTIVITY, payload: true });
        return response;
      })
      .catch((e) => {
        dispatch({ type: SET_USER_ACTIVITY_ERROR, payload: false });
      });
  };
}

const updateNonLinkedExercises = (lessonModules) => {
  let firstLinkedExercise = "",
    updatedModules = [];
  updatedModules = lessonModules.map((lessonModule) => {
    if (!!lessonModule.exerciseID) {
      if (!lessonModule.excercise_linkedID) {
        //debugger;
        return { ...lessonModule, isNotLinked: true };
      } else {
        if (!firstLinkedExercise) {
          firstLinkedExercise = lessonModule.excercise_linkedID;
        }
        return lessonModule;
      }
    } else {
      return lessonModule;
    }
  });

  if (firstLinkedExercise) {
    /*  const index = updatedModules.indexOf(
      (mod) => mod.exerciseID == firstLinkedExercise
    );
 */

    updatedModules = updatedModules.map((mod) => {
      if (mod.exerciseID == firstLinkedExercise) {
        delete mod.isNotLinked;
        return mod;
      } else return mod;
    });
    //delete updatedModules[index].isNotLinked;
  }

  return updatedModules;
};
