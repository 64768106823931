import { ACTIONS } from "../actionTypes/index";

const {
  SET_USER_COURSES,
  SET_USER_COURSES_ERROR,
  SET_USER_RECOMMEND_COURSES,
  SET_USER_RECOMMEND_COURSES_ERROR,
  COURSE_REGISTRATION_SUCCESS,
  COURSE_REGISTRATION_ERROR,
  SET_ANNOUNCEMENT,
  SET_ANNOUNCEMENT_ERROR,
} = ACTIONS;

const INITIAL_STATE = {
  userCourses: [],
  userCoursesError: null,
  areUserCoursesLoading: true,
  userRecommendCourses: [],
  userRecommendCoursesError: null,
  areUserRecommendCoursesLoading: true,
  coursesRegistrationSuccess: null,
  coursesRegistrationError: null,
  announcement: [],
  announcementError: null,
  isAnnouncementLoading: true,
  headerColor: "white",
};
const courses = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_COURSES:
      //console.log("action", action);
      return {
        ...state,
        userCourses: action.payload,
        areUserCoursesLoading: false,
      };
    case SET_USER_COURSES_ERROR:
      return {
        ...state,
        userCoursesError: action.payload,
        areUserCoursesLoading: false,
      };
    case SET_USER_RECOMMEND_COURSES:
      return {
        ...state,
        userRecommendCourses: action.payload,
        areUserRecommendCoursesLoading: false,
      };
    case SET_USER_RECOMMEND_COURSES_ERROR:
      return {
        ...state,
        userRecommendCoursesError: action.payload,
        areUserRecommendCoursesLoading: false,
      };

    case SET_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.payload,
        isAnnouncementLoading: false,
      };
    case SET_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        announcementError: action.payload,
        isAnnouncementLoading: false,
      };

    case COURSE_REGISTRATION_SUCCESS:
      return {
        ...state,
        coursesRegistrationSuccess: action.payload.data,
      };
    case COURSE_REGISTRATION_ERROR:
      return {
        ...state,
        coursesRegistrationError: action.payload,
      };

    default:
      return state;
  }
};
export default courses;
