import BaseService from './BaseService';

import { getOptionsAsObject, removeKey, setOptions } from '../utils/LocalStorage';

export const getUserDetails = () => {
  return getOptionsAsObject(`userProfile`);
};

export const setUserUserDetailsForManageStudent = (data) => {
  if (data === null) {
    removeKey('manage_user_admin_panel');
    return;
  }
  setOptions('manage_user_admin_panel', JSON.stringify(data));
};
const getUserUserDetailsForManageStudent = () => {
  return getOptionsAsObject('manage_user_admin_panel');
};

class Service extends BaseService {
  ageNumbers = [
    { value: '6', id: '6' },
    { value: '7', id: '7' },
    { value: '8', id: '8' },
    { value: '9', id: '9' },
    { value: '10', id: '10' },
    { value: '11', id: '11' },
    { value: '12', id: '12' },
    { value: '13', id: '13' },
    { value: '14', id: '14' },
    { value: '15', id: '15' },
    { value: '16', id: '16' },
    { value: '17', id: '17' },
    { value: '18', id: '18' },
    { value: '18+', id: '18+' },
  ];

  getProgrammes = (params = null) => {
    const { collegeID, userID } = getUserDetails();
    let url = `/user/get-all-programme?collegeID=${collegeID}&userID=${userID}`;
    if (params) {
      const { start = 0, search = '' } = params;
      url = `${url}&start=${start}&limit=10`;
      if (search) {
        url = `${url}&search=${search}`;
      }
    }

    return this.request({
      url,
    });
  };
  deleteProgramme = (programmeID) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/delete-programme`,
      body: { programmeID, userID, collegeID },
    });
  };
  addProgramme = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/add-programme`,
      body: { ...body, userID, collegeID },
    });
  };
  addClass = (body) => {
    const { collegeID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/admin/add-class`,
      body: { ...body, collegeID },
    });
  };
  addCourse = (body) => {
    const { collegeID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/admin/add-course`,
      body: { ...body, collegeID },
    });
  };
  getUsers = (params = null) => {
    const { collegeID } = getUserDetails();
    let url = `/user/new-user-listing?collegeID=${collegeID}`;
    if (params) {
      const { start = 0, search = '', roleID, classID } = params;
      if (classID) {
        url = `${url}&classID=${params.classID}`;
      }
      url = `${url}&start=${start}&limit=10&roleID=${roleID}`;
      if (search) {
        url = `${url}&search=${search}`;
      }
    }

    return this.request({
      url,
    });
  };
  getClasses = (params = null) => {
    const { collegeID, userID } = getUserDetails();
    let url = `/user/all-classes?collegeID=${collegeID}&userID=${userID}`;
    if (params) {
      const { start = 0, search = '' } = params;
      url = `${url}&start=${start}&limit=10`;
      if (search) {
        url = `${url}&search=${search}`;
      }
    }

    return this.request({
      url,
    });
  };
  getMeetingsByClass = (classID) => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/meeting/get-all-meetings-by-classID/${collegeID}/${classID}`,
    });
  };
  getAges = () => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/user/get-all-ages/${collegeID}`,
    });
  };
  getTeachers = (params = null) => {
    const { collegeID, userID } = getUserDetails();

    let url = `/admin/get-all-teachers?collegeID=${collegeID}&userID=${userID}`;
    if (params) {
      const { start = 0, search = '' } = params;
      url = `${url}&start=${start}&limit=10`;
      if (search) {
        url = `${url}&search=${search}`;
      }
    }
    return this.request({
      url,
    });
  };

  getAttendanceByMeetingId = (classID, meetingID) => {
    return this.request({
      url: `/meeting/get-attendance-users/${classID}/${meetingID}`,
    });
  };

  getAllTeachersWithClass = () => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      url: `/Meeting/get-teachers-of-classes/${collegeID}/${userID}`,
    });
  };

  getAllStudentsWithClass = () => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      url: `/Meeting/get-student-of-classes/${collegeID}/${userID}`,
    });
  };

  getStudents = (params = null) => {
    const { collegeID, userID } = getUserDetails();
    let url = `/admin/get-all-students?collegeID=${collegeID}&userID=${userID}`;
    if (params) {
      const { start = 0, search = '' } = params;
      url = `${url}&start=${start}&limit=10`;
      if (search) {
        url = `${url}&search=${search}`;
      }
    }
    return this.request({
      url,
    });
  };
  getCountries = () => {
    const { collegeID } = getUserDetails();
    return this.request({
      url: `/admin/get-countries`,
      params: { collegeID },
    });
  };
  getTimeZones = (country_code) => {
    return this.request({
      url: `/admin/get-country-timezone/${country_code}`,
    });
  };

  getAgeNumbers = () => {
    return this.ageNumbers;
  };

  enrollStudents = (body) => {
    const { collegeID, userID } = getUserDetails();
    // const url=`/user/user-multi-enroll-class`
    const url = `/user/user-single-enroll-class`;
    return this.request({
      method: 'POST',
      url,
      body: { ...body, collegeID, userID },
    });
  };
  getUserName = (body) => {
    const { collegeID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/get-username`,
      body,
    });
  };

  bulkUpload = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      type: 'multipart/form-data',
      url: `/user/bulk-user-import`,
      body: { ...body, collegeID, created_by: userID },
    });
  };
  addUser = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/user-register`,
      body: { ...body, collegeID, created_by: userID },
    });
  };
  deleteUser = (body) => {
    const { collegeID, userID } = getUserDetails();

    return this.request({
      method: 'POST',
      url: `/user/delete-user`,
      body: { ...body, collegeID, created_by: userID },
    });
  };
  deleteClass = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/admin/delete-class`,
      body: { ...body, collegeID, created_by: userID },
    });
  };
  updateLiveStatus = (body) => {
    const { userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/update-live-status`,
      body: { ...body, userID },
    });
  };

  // Manage Courses
  getCourses = () => {
    const { collegeID, userID } = getUserDetails();

    return this.request({
      url: `/user/get-all-courses/${userID}/${collegeID}`,
    });
  };
  getCourseUsers = (courseID) => {
    const { collegeID } = getUserDetails();

    return this.request({
      url: `/user/user-course-listing/${collegeID}/${courseID}`,
    });
  };
  getCourseClasses = (courseID) => {
    const { collegeID } = getUserDetails();

    return this.request({
      url: `/user/course-class-listing/${collegeID}/${courseID}`,
    });
  };
  getCourseProgramme = (courseID) => {
    const { collegeID } = getUserDetails();

    return this.request({
      url: `/user/course-programme-listing/${collegeID}/${courseID}`,
    });
  };

  viewUserDetails = (userID) => {
    const { collegeID } = getUserDetails();

    return this.request({
      url: `/user/view-user/${userID}`,
    });
  };

  resetPassword = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/reset-password`,
      body: { ...body, collegeID, created_by: userID },
    });
  };
  enrollUsers = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/multi-users-enroll-course`,
      body: { ...body, collegeID, userID },
    });
  };
  enrollClasses = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/multi-class-enroll-course`,
      body: { ...body, collegeID, userID },
    });
  };
  getS3Details = () => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      url: `/admin/s3-details/${collegeID}/${userID}`,
    });
  };
  addS3Details = (body) => {
    const { collegeID, userID } = getUserDetails();
    console.log('body', body);
    return this.request({
      method: 'POST',
      type: null,
      url: `/admin/store-S3-details`,
      body: { ...body, collegeID, userID },
    });
  };
  ViewClassDetails = (programmeID) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      url: `/admin/view-class-details/${collegeID}/${userID}/${programmeID}`,
    });
  };
  ViewCourseDetails = (programmeID) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      url: `/admin/view-course-details/${collegeID}/${userID}/${programmeID}`,
    });
  };
  enrollProgrammes = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/user/multi-programme-enroll-course`,
      body: { ...body, collegeID, userID },
    });
  };
  getAttendanceByMeetingAndClass = (body) => {
    const { collegeID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/meeting/users-for-attendance`,
      body: { ...body, collegeID },
    });
  };
  getTeacherAttendanceReport = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/meeting/teacher-attendance-report`,
      body: { ...body, collegeID, created_by: userID },
    });
  };
  getStudentAttendanceReport = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/meeting/student-attendance-report`,
      body: { ...body, collegeID, created_by: userID },
    });
  };
  getClassAttendanceReport = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/meeting/class-attendance-report`,
      body: { ...body, collegeID, created_by: userID },
    });
  };
  getMeetingByClassID = (classID) => {
    // const { collegeID, userID } = getUserDetails();
    return this.request({
      url: `/meeting/get-meetingSubject-by-classID/${classID}`,
    });
  };
  updateAttendanceByMeetingAndClass = (body) => {
    const { collegeID, userID } = getUserDetails();
    return this.request({
      method: 'POST',
      url: `/meeting/take-user-attendance`,
      body: { ...body, created_by: userID },
    });
  };
  getStudentListForClass = ({ classID, userID, collegeID }) => {
    return this.request({
      url: `/admin/class-students-details/${collegeID}/${userID}/${classID}`,
    });
  };
  getSSMList = () => {
    const { collegeID = 1, userID } = getUserDetails();
    return this.request({
      url: `/user/ssm_list/${collegeID}/${userID}`,
    });
  };
  updateActiveStatusWithReason = ({ flag, reason, classID }) => {
    const { userID } = getUserDetails();

    return this.request({
      method: 'POST',
      url: `/admin/update-class-flag`,
      body: {
        classID,
        userID,
        flag,
        reason,
      },
    });
  };
  // getAvailableBadges=()=>{
  //   const { collegeID=1,userID} = getUserDetails();
  //   const  url= `/profile/get_badges?collegeID=${collegeID}&userID=${userID}`;
  //   return this.request({
  //     url
  //   });
  // }
  getAssignedBadge = (studentID, classID) => {
    const { collegeID = 1, userID } = getUserDetails();
    const url = `/teacher/get_badges_template?collegeID=${collegeID}&studentID=${studentID}&given_by=${userID}&classID=${classID}`;
    return this.request({
      url,
    });
  };
  assignBadge = ({ badgeID, studentID, classID, recognition }) => {
    const { userID, collegeID = 1 } = getUserUserDetailsForManageStudent();
    return this.request({
      method: 'POST',
      url: `/teacher/badgeAwarded`,
      body: {
        collegeID,
        studentID: studentID,
        templateID: badgeID,
        userID,
        classID,
        recognition,
      },
    });
  };

  // fetch class List of a teacher
  getClassesByTeacher = (params) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    let url = `/teacher/all-classes-by-teacher?collegeID=${collegeID}&userID=${userID}`;
    if (params) {
      const { start = 0, search = '' } = params;
      url = `${url}&start=${start}&limit=10`;
      if (search) {
        url = `${url}&search=${search}`;
      }
    }
    return this.request({ url });
  };

  getAllStudentByTeacher = (params) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    let url = `/teacher/get-all-students-by-teacher?collegeID=${collegeID}&userID=${userID}`;
    if (params) {
      const { start = 0, search = '', classID } = params;
      url = `${url}&start=${start}&limit=10`;
      if (search) {
        url = `${url}&search=${search}`;
      }
      if (classID) {
        url = `${url}&classID=${classID}`;
      }
    }
    return this.request({ url });
  };

  getCityByCountryID = (country_code, searchCity) => {
    if (country_code) {
      return this.request({
        url: `/admin/get-country-city?country_code=${country_code}&search=${searchCity}`,
      });
    }
  };
  getGrades = () => {
    return this.request({
      url: `/admin/get_grades`,
    });
  };
  //Achievements

  getAllAchievements = ({ studentID, start = 0 }) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    const url = `/teacher/get_all_achievements?collegeID=${collegeID}&userID=${userID}&studentID=${studentID}&start=${start}&limit=5`;
    return this.request({ url });
  };
  createAchievements = ({ payload }) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    const body = {
      ...payload,
      collegeID,
      userID,
    };
    const url = `/teacher/add_update_achievement`;
    return this.request({ url, method: 'POST', body });
  };
  deleteAchievementByID = ({ studentID, achievementID }) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    const url = `/teacher/delete-achievement`;

    const body = {
      studentID,
      achievementID,
      collegeID,
      userID,
    };

    return this.request({ url, method: 'POST', body });
  };

  getSalaryWiseAttendance = (params) => {
    const { collegeID = 1, userID } = getUserDetails();
    let url = `/admin/salary_wise_teachers_attendence?collegeID=${collegeID}&userID=${userID}`;
    if (params) {
      const { start = 0, from_date = '', to_date = '', teacherID = '' } = params;
      url = `${url}&start=${start}&limit=10`;
      if (from_date && to_date) {
        url = `${url}&from_date=${from_date}&to_date=${to_date}`;
      }
      console.log({ teacherID });
      if (teacherID) {
        url = `${url}&teacherID=${teacherID}`;
      }
    }

    return this.request({ url });
  };

  // skills

  getAssignableSkills = ({ studentID }) => {
    const { userID, collegeID = 1 } = getUserUserDetailsForManageStudent();
    const url = `/teacher/skills_ratings?collegeID=${collegeID}&userID=${studentID}&given_by=${userID}`;
    return this.request({
      url,
    });
  };
  submitAssignedSkills = (body) => {
    const { userID, collegeID = 1 } = getUserUserDetailsForManageStudent();
    const url = `/teacher/skills_ratings`;
    return this.request({
      url,
      method: 'POST',
      body: { ...body, given_by: userID, collegeID },
    });
  };
  // certificates
  getAllClassesByTeacherStudent = (studentID) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    const url = `/teacher/all-classes-by-teacher-Student?collegeID=${collegeID}&studentID=${studentID}&userID=${userID}&templateType=1`;
    return this.request({
      url,
    });
  };
  getAssignedCertificates = (studentID, classID) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    const url = `/teacher/certifyTemplate?collegeID=${collegeID}&studentID=${studentID}&userID=${userID}&templateType=1&classID=${classID}`;
    return this.request({
      url,
    });
  };
  assignCertificates = ({ templateID, studentID, classID, project }) => {
    const { userID, collegeID = 1 } = getUserUserDetailsForManageStudent();
    return this.request({
      method: 'POST',
      url: `/teacher/certificateAwarded`,
      body: {
        collegeID,
        studentID,
        templateID,
        userID,
        classID,
        project,
      },
    });
  };
  deleteCertificates = ({ templateID, studentID, classID }) => {
    const { userID, collegeID = 1 } = getUserUserDetailsForManageStudent();
    return this.request({
      method: 'POST',
      url: `/teacher/certificateDelete`,
      body: {
        collegeID,
        studentID,
        templateID,
        userID,
        classID,
      },
    });
  };

  getRecognitionList = ({ templateID, studentID, classID }) => {
    const { userID, collegeID = 1 } = getUserUserDetailsForManageStudent();
    return this.request({
      method: 'GET',
      url: `/teacher/get_recognition?collegeID=${collegeID}&studentID=${studentID}&templateID=${templateID}&userID=${userID}&classID=${classID}`,
    });
  };

  
  
  // Internship details
  fetchInterShip = ({ studentID }) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    return this.request({
      url: `/api/internship_social_link?collegeID=${collegeID}&studentID=${studentID}&userID=${userID}`,
    });
  };
  saveInternShipDetails = (body) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    console.log(body);
    return this.request({
      method: 'POST',
      url: `/api/internship_social_link`,
      body: {
        ...body,
        collegeID,
        userID,
      },
    });
  };
  // Project details
  fetchProjects = ({ studentID }) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    return this.request({
      url: `/api/project_social_link?collegeID=${collegeID}&studentID=${studentID}&userID=${userID}`,
    });
  };
  saveProjectDetails = (body) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    console.log(body);
    return this.request({
      method: 'POST',
      url: `/api/project_social_link`,
      body: {
        ...body,
        collegeID,
        userID,
      },
    });
  };
  // courses list
  getAllCoursesByStudentAndTeacher = ({ studentID, classID, start = 0, search = '' }) => {
    const { collegeID = 1, userID } = getUserUserDetailsForManageStudent();
    let url = `/teacher/get_all_course_details_by_student_teacher?collegeID=${collegeID}&userID=${userID}&studentID=${studentID}&classID=${classID}&start=${start}&limit=12`;
    if (search) {
      url = `${url}&search=${search}`;
    }
    return this.request({ url });
  };
  // Collegs list
  getAllColleges() {
    let url = `admin/get_colleges?userID=1`;
    return this.request({ url });
  }
  // teacher list based on user id
  getAllTeacherForSchoolAdmin(collegeID, userID) {
    let url = `/admin/get-all-teachers-by-college?collegeID=${collegeID}&userID=${userID}`;
    return this.request({ url });
  }
}

export default Service;
