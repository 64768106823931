import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import login from "./login";
import courses from "./courses";
import course from "./course";
import module from "./module";
import compiler from "./compiler";
import quiz from "./quiz";

const rootReducer = combineReducers({
  form: formReducer,
  login,
  quiz,
  courses,
  course,
  module,
  compiler,
});

export default rootReducer;
