import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { every } from 'lodash';
import { withRouter } from 'react-router';

import Header from '../../components/Header';
import { getUserDetails } from '../../services/ManagementService';
import Authorize from '../Authorize/Authorize';
import PrivateRoute from './privateRoute';

const LoginForm = React.lazy(() => import('../../views/authentication/login'));
const Dashboard = React.lazy(() => import('../../views/Dashboard/index'));
const Profile = React.lazy(() => import('../../views/Profile/index'));
const Course = React.lazy(() => import('../../views/Course'));
const ForgotPassword = React.lazy(() => import('../../views/ForgotPassword/index'));
const Modules = React.lazy(() => import('../../views/Modules/Modules'));
const VideoPage = React.lazy(() => import('../../views/Video'));
const Classes = React.lazy(() => import('../../views/Classes'));
const Management = React.lazy(() => import('../../views/Management'));
const Attendance = React.lazy(() => import('../../views/Classes/Attendance'));
const Servers = React.lazy(() => import('../../views/Servers'));
const Quizzes = React.lazy(() => import('../../views/Quizzes'));
const Logs = React.lazy(() => import('../../views/Logs'));
const AsyncStudentDashboard = React.lazy(() => import('../../views/student-dashboard'));
const AsyncTeacherDashboard = React.lazy(() => import('../../views/teacher-dashboard'));

const NAVIGATION_EXCLUDED = ['/login', '/logins', '/authorise', '/forgotpassword', '/classes/recording-view', '/authorize'];

class App extends Component {
  state = {
    isStyle: false,
  };

  render() {
    // localStorage.clear()
    const { location } = this.props;
    const { role = 1 } = getUserDetails();

    const param = location.search;
    const params = param.replace(/%20/g, '');
    // console.log(params);
    return (
      <React.Fragment>
        <React.Suspense fallback="">
          {every(NAVIGATION_EXCLUDED, (o) => location.pathname !== o) && <Header />}
          <Switch>
            {/* <Route path="/" exact={true} component={LoginForm} /> */}
            <Route path="/authorize" search={params} component={Authorize} />
            <Route path="/login" component={LoginForm} />
            <Route path="/logins" component={LoginForm} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/courses" component={Dashboard} />
            <PrivateRoute path="/training" component={Dashboard} />
            {role == 3 && <PrivateRoute path="/dashboard" component={AsyncStudentDashboard} />}
            {role == 4 && <PrivateRoute path="/dashboard" component={AsyncTeacherDashboard} />}

            <PrivateRoute hideSidebar path="/course/:courseId/lesson/:lessonId/module" component={Modules} />
            <PrivateRoute hideSidebar path="/video/:courseID/lesson/:lessonID/module/:moduleID" component={VideoPage} />

            <PrivateRoute path="/course/:courseId" component={Course} />
            <PrivateRoute
              path="/classes"
              component={Classes}
              hideSidebar={({ location }) => {
                return location.pathname == '/classes/recording-view';
              }}
            />
            <PrivateRoute path="/management" component={Management} />
            <PrivateRoute path="/attendance" component={Attendance} />
            <PrivateRoute path="/servers" component={Servers} />
            <PrivateRoute path="/quizzes" component={Quizzes} />
            <PrivateRoute path="/logs" component={Logs} />
            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
