import { ACTIONS } from "../actionTypes/index";

import { merge, cloneDeep } from "lodash";
const {
  SET_QUIZ_QUESTIONS,
  SET_QUIZ_QUESTIONS_ERROR,
  SET_QUIZ_QUESTIONS_LOADING,
  SET_SUBMITTED_QUIZ_DETAILS,
  SET_SUBMITTED_QUIZ_DETAILS_LOADING,
  SET_SUBMITTED_QUIZ_DETAILS_ERROR,
} = ACTIONS;

const INITIAL_STATE = {
  quizQuestions: [],
  areQuizQuestionsLoading: true,
  quizQuestionsError: null,
  submittedQuizDetails: null,
  areSubmittedQuizDetailsLoading: false,
  submittedQuizDetailsError: null,
};
const _module = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_QUIZ_QUESTIONS_LOADING: {
      return {
        ...state,
        areQuizQuestionsLoading: true,
      };
    }
    case SET_QUIZ_QUESTIONS: {
      const quizQuestions = action.payload;
      return {
        ...state,
        quizQuestions,
        areQuizQuestionsLoading: false,
      };
    }
    case SET_QUIZ_QUESTIONS_ERROR:
      return {
        ...state,
        quizQuestionsError: action.payload,
      };
    case SET_SUBMITTED_QUIZ_DETAILS_LOADING: {
      return {
        ...state,
        areSubmittedQuizDetailsLoading: true,
      };
    }
    case SET_SUBMITTED_QUIZ_DETAILS: {
      const submittedQuizDetails = action.payload;
      return {
        ...state,
        submittedQuizDetails,
        areSubmittedQuizDetailsLoading: false,
      };
    }
    case SET_SUBMITTED_QUIZ_DETAILS_ERROR:
      return {
        ...state,
        submittedQuizDetailsError: action.payload,
      };
  }
  return state;
};
export default _module;
