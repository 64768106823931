import keyMirror from "key-mirror";

export const ACTIONS = keyMirror({
  LOGIN_USER: null,
  SET_USER_AUTHENTICATION: null,
  SET_USER_AUTHENTICATION_ERROR: null,

  USER_LOGOUT_WATCHER: null,
  USER_LOGOUT_SUCCESS: null,
  USER_LOGOUT_ERROR: null,

  FORGOT_PASSWORD_WATCHER: null,
  SET_FORGOT_PASSWORD: null,
  SET_FORGOT_PASSWORD_ERROR: null,

  VERIFICATION_CODE_WATCHER: null,
  VERIFICATION_CODE_SUCCESS: null,
  VERIFICATION_CODE_ERROR: null,

  GET_USER_PROFILE_BY_TOKEN_WATCHER: null,
  SET_USER_PROFILE_BY_TOKEN: null,
  SET_USER_PROFILE_BY_TOKEN_ERROR: null,

  GET_USER_COURSES_WATCHER: null,
  SET_USER_COURSES: null,
  USER_COURSES_LOADING: null,
  SET_USER_COURSES_ERROR: null,

  GET_USER_RECOMMEND_COURSES_WATCHER: null,
  SET_USER_RECOMMEND_COURSES: null,
  USER_RECOMMEND_LOADING: null,
  SET_USER_RECOMMEND_COURSES_ERROR: null,

  COURSE_REGISTRATION_WATCHER: null,
  COURSE_REGISTRATION_SUCCESS: null,
  COURSE_REGISTRATION_ERROR: null,

  GET_USER_BY_ID_WATCHER: null,
  SET_USER_BY_ID: null,
  SET_USER_BY_ID_ERROR: null,

  GET_ANNOUNCEMENT_WATCHER: null,
  SET_ANNOUNCEMENT: null,
  ANNOUNCEMENT_LOADING: null,
  SET_ANNOUNCEMENT_ERROR: null,

  RESET_PASSWORD_WATCHER: null,
  RESET_PASSWORD_SUCCESS: null,
  RESET_PASSWORD_ERROR: null,

  SET_COURSE: null,
  SET_COURSE_ERROR: null,
  SET_LESSONS: null,
  SET_LESSONS_ERROR: null,
  SET_MODULES: null,
  SET_MODULES_ERROR: null,
  SET_CURRENT_MODULE: null,
  SET_MODULES_LOADING: null,

  SET_MODULE: null,
  SET_NEXT_MODULE: null,
  SET_PREV_MODULE: null,
  SET_ACTIVE_MODULE: null,
  SET_ACTIVE_MODULE_ID: null,

  SET_CODE_OUTPUT: null,
  SET_CODE_ERROR: null,
  SET_CODE_OUTPUT_LOADING: null,
  SET_OUTPUT_AND_ERROR: null,
  SET_USE_SOLUTION: null,
  SET_USE_SOLUTION_ERROR: null,
  SET_USE_HINT_ERROR: null,
  SET_USE_HINT: null,
  SET_USER_ACTIVITY: null,
  GET_USER_ACTIVITY: null,
  SET_USER_ACTIVITY_ERROR: null,
  GET_USER_ACTIVITY_ERROR: null,
  SET_HEADER_COLOR: null,

  SET_QUIZ_QUESTIONS: null,
  SET_QUIZ_QUESTIONS_ERROR: null,
  SET_QUIZ_QUESTIONS_LOADING: null,
  SET_SUBMITTED_QUIZ_DETAILS: null,
  SET_SUBMITTED_QUIZ_DETAILS_LOADING: null,
  SET_SUBMITTED_QUIZ_DETAILS_ERROR: null,
});
