import { ACTIONS } from "../actionTypes";
import { get as _get } from "lodash";

const {
  LOGIN_USER,
  SET_USER_AUTHENTICATION,
  SET_USER_AUTHENTICATION_ERROR,
  SET_FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD_ERROR,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  VERIFICATION_CODE_SUCCESS,
  VERIFICATION_CODE_ERROR,
  SET_USER_PROFILE_BY_TOKEN,
  SET_USER_PROFILE_BY_TOKEN_ERROR,
} = ACTIONS;

const INITIAL_STATE = {
  userAuthentication: null,
  userAuthenticationError: null,
  verificationCodeSuccess: null,
  verificationCodError: null,
};
const login = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER: {
    }
    case SET_USER_AUTHENTICATION:
      return {
        ...state,
        userAuthentication: _get(action, "payload.data.data", ""),
        userProfile: _get(action, "payload.userProfile", ""),
      };
    case SET_USER_AUTHENTICATION_ERROR:
      return {
        ...state,
        userAuthenticationError: action.payload,
      };

    case SET_FORGOT_PASSWORD:
      return {
        ...state,
        setForgetPassword: action.payload.data,
      };
    case SET_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        setForgetPasswordError: action.payload,
      };

    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        userLogoutSuccess: action.payload.data,
      };
    case USER_LOGOUT_ERROR:
      return {
        ...state,
        userLogoutError: action.payload,
      };

    case VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        verificationCodeSuccess: action.payload.data,
      };
    case VERIFICATION_CODE_ERROR:
      return {
        ...state,
        verificationCodError: action.payload,
      };

    case SET_USER_PROFILE_BY_TOKEN:
      return {
        ...state,
        userProfile: _get(action, "payload.userProfile", ""),
      };
    case SET_USER_PROFILE_BY_TOKEN_ERROR:
      return {
        ...state,
        userProfile: {},
      };

    default:
      return state;
  }
};
export default login;
