import { ACTIONS } from "../actionTypes/index";

import {
  getOptionsAsObject,
  setOptionsAsObject,
} from "../../utils/LocalStorage";
const {
  SET_CODE_OUTPUT,
  SET_CODE_ERROR,
  SET_CODE_OUTPUT_LOADING,
  SET_OUTPUT_AND_ERROR,
} = ACTIONS;

const INITIAL_STATE = {
  isError: false,
  output: "",
  error: "",
  combined: "",
  killedByContainer: "",
  compilerError: "",
  isOutputLoading: false,
  isAPICall: false,
};
const compiler = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CODE_OUTPUT_LOADING: {
      return {
        ...state,
        isOutputLoading: action.payload,
      };
    }
    case SET_CODE_OUTPUT: {
      const currentModule = getOptionsAsObject("currentModule");

      const { lessonId, courseId } = currentModule,
        { exerciseID } = action;
      const { stdout: output, stderr: error, isAPICall } = action.payload || {};
      let result = {
        [`output_${exerciseID}`]: output,
        [`error_${exerciseID}`]: error,
      };
      setOptionsAsObject(`${courseId}_${lessonId}_result`, result);

      return {
        ...state,
        ...action.payload,
        output,
        error,
        isOutputLoading: false,
        isAPICall,
      };
    }
    case SET_CODE_ERROR:
      return {
        ...state,
        compilerError: action.payload,
        isOutputLoading: false,
      };

    case SET_OUTPUT_AND_ERROR: {
      const { output, error } = action.payload;
      return {
        ...state,
        output,
        error,
      };
    }
    default:
      return state;
  }
};
export default compiler;
