import request from 'superagent';
import jwtencode from 'jwt-encode';
import jwtdecode from 'jwt-decode';

import { isArray, isNull, each, isObject } from 'lodash';
import config from '../config';
import { SERVER_ERROR_CODES } from '../utils/constants';
//import { SuccessResponse, ErrorResponse } from "../utils/Responses";

import ServerError from '../utils/errors/ServerError';
import NetworkError from '../utils/errors/NetworkError';
import AuthorizationError from '../utils/errors/AuthorizationError';
import { getOptionsAsObject } from '../utils/LocalStorage';

const getUserDetails = () => {
  return getOptionsAsObject(`userProfile`);
};

const { UNAUTHORIZED, CONNECTION_ABORTED, INTERNAL_SERVER_ERROR, NO_CONNECTION_OR_SERVER_IS_NOT_AVAILABLE } =
  SERVER_ERROR_CODES;

const UNAUTHORIZED_ERROR_TEXT = 'Authentication is required to access this resource';
const NO_CONNECTION_ERROR_TEXT = 'No Internet connection. Make sure that Wi-Fi or cellular mobile data is turned on.';
const INTERNAL_SERVER_ERROR_TEXT =
  'Internal Server Error.\n During the query execution, errors occurred on the server. Please, contact Support.';
const SERVER_IS_NOT_AVAILABLE_ERROR_TEXT = 'Server is not available. Please, try to connect again later.';

const errors = {
  unauthorized: {
    error: {
      code: UNAUTHORIZED,
      message: UNAUTHORIZED_ERROR_TEXT,
    },
  },
  serverInternal: {
    error: {
      code: INTERNAL_SERVER_ERROR,
      message: INTERNAL_SERVER_ERROR_TEXT,
    },
  },
  noConnectionOrServerIsNotAvailable: {
    error: {
      code: NO_CONNECTION_OR_SERVER_IS_NOT_AVAILABLE,
      message: `HTTP Request has been terminated.\n Possible causes:\n\n - ${NO_CONNECTION_ERROR_TEXT}\n - ${SERVER_IS_NOT_AVAILABLE_ERROR_TEXT}`,
    },
  },
};

function getRoute(route = '') {
  return route.includes('/') ? route : route + '/';
}

function onSuccess(response) {
  const type = response.headers['content-type'];
  const Authorization = response.headers['Authorization'];

  /* if (isAllowedMimeType(type)) {
    const disposition = response.headers["content-disposition"];

    return {
      data: response.body,
      name: /filename=(.*\.\w+)/.exec(disposition)[1] || "unnamed.txt",
    };
  } */
  // console.log(response);

  const { body = {}, statusCode: status } = response,
    { status: responseStatus = '' } = body || {};

  if (!isNull(body)) {
    if ((status === 200 || status === 201) && body.success !== false) {
      if (isArray(body)) {
        return { data: body, status: true };
      } else {
        return { ...body, status: responseStatus === 'success' };
      }
    }

    if (body.crossDomain || body.code === CONNECTION_ABORTED) {
      if (status) throw new ServerError(errors.serverInternal.error);
      throw new NetworkError(errors.noConnectionOrServerIsNotAvailable.error);
    }
  }

  throw new ServerError({ status, ...body.error });
}

function onFailure({ code, status, response, crossDomain }) {
  if (status == 406) {
    localStorage.clear();
    window.location.reload();
  }

  if (code === 'ABORTED' || crossDomain) {
    if (status) throw new ServerError(errors.serverInternal.error);
    throw new NetworkError(errors.noConnectionOrServerIsNotAvailable.error);
  }

  if (response) {
    let { body = {} } = JSON.parse(response.text) || {};

    if (!(body && body.error)) {
      throw new AuthorizationError(errors.unauthorized);
    }

    throw new ServerError({
      body,
      status,
      ...errors.serverInternal.error,
      ...body.error,
    });
  }
  return { data: null };
}

class BaseService {
  constructor(route) {
    this.route = getRoute(route);
    //this.save = this.save.bind(this);
    this.find = this.find.bind(this);
    // this.update = this.update.bind(this);
    // this.delete = this.delete.bind(this);
    this.request = this.request.bind(this);
    //  this.findById = this.findById.bind(this);
  }
  async find(params) {
    return this.request({ params, url: this.route });
  }

  request(opts = {}) {
    opts = Object.assign({ method: 'GET', url: '' }, opts);

    let { method, params, body, url, headers, authheader, Authorization, type = 'json', useCompilerUrl = false } = opts;

    const { baseURL, compilerURL, compilerKey } = config;

    const { userID, username, email, role, authorization } = getUserDetails();
    let jwttoken = authorization;

    url = `${useCompilerUrl ? compilerURL : baseURL}${url}`;
    let rq = null;
    switch (method) {
      case 'GET': {
        rq = request.get(url).type(type).query(params);

        rq.set('Authorization', `${jwttoken}`);
        break;
      }

      case 'POST': {
        rq = request.post(url);
        rq.set('Authorization', `${jwttoken}`);
        if (type !== null) {
          rq = rq.type(type);
        }
        if (headers) {
        }

        if (useCompilerUrl) {
          // rq.set("X-API-Key", compilerKey);
          rq.set('Authorization', compilerKey);
        }
        if (type === 'multipart/form-data' || type === 'application/x-www-form-urlencoded') {
          each(body, (v, k) => {
            // console.log(k);
            if (v instanceof File) {
              rq = rq.attach(k, v);
            } else {
              if (isObject(v)) {
                v = JSON.stringify(v);
              }
              rq = rq.field(k, v);
            }
          });
        } else {
          rq.send(body);
        }
        // console.log(rq);
        break;
      }
    }
    return rq.then(onSuccess, onFailure);
  }
}

export default BaseService;
