import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getOptionsAsObject } from '../../utils/LocalStorage';
import { isValidJSON } from '../../utils/utils';
import './Sidebar.scss';

const MenuList = () => {
  const history = useHistory(),
    { pathname } = useLocation(),
    { menus = [] } = getOptionsAsObject('userProfile'),
    [menuExpanded, setMenuExpanded] = React.useState('');

  useEffect(() => {
    const [menu = {}] = menus.filter((menu) => pathname.includes(menu.url));
    setMenuExpanded(menu.menuID || 1);
  }, []);

  const isValidURL = (string) => {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
  };

  const handleClick = (url, id, hasSubMenus) => () => {
    if (isValidURL(url.substring(1))) {
      window.open(url.substring(1), '_blank');
      return;
    }

    !hasSubMenus && history.push(url);
    if (id === menuExpanded && hasSubMenus) {
      setMenuExpanded('id');
    } else if (url == '/https://clevered.zendesk.com/hc/en-us/requests/new') {
      window.open('https://clevered.zendesk.com/hc/en-us/requests/new', '_blank');
    } else {
      setMenuExpanded(id);
    }
  };

  const activeEvent = isValidJSON(localStorage.getItem('current_active_event')) ? JSON.parse(localStorage.getItem('current_active_event')) : {};
  const { external_link: link = 'https://us06web.zoom.us/meeting/register/tZMlc-yrpj4rEtwiQR6CwKb7E73j0abLsZSn#/registration', title } = activeEvent;

  return (
    <nav>
      <ul className="sidebar--ul">
        {menus.map((menu) => {
          const hasModules = !!menu.subMenus;
          return (
            <React.Fragment key={menu.menuID}>
              <li
                onClick={handleClick(menu.url, menu.menuID, hasModules)}
                className={cn('sidebar--li', {
                  active: pathname === menu.url,
                })}
              >
                {menu.title}
                {menuExpanded === menu.menuID ? hasModules && <ExpandLess /> : hasModules && <ExpandMore />}
              </li>
              {hasModules ? (
                <Collapse component={'ul'} in={menuExpanded === menu.menuID} timeout="auto" unmountOnExit>
                  {menu.subMenus
                    ? menu.subMenus.map((subMenuItem, _idx) => {
                        return (
                          <li
                            key={menu.menuID + _idx}
                            onClick={handleClick(subMenuItem.url, menu.menuID)}
                            className={cn('sidebar--li submenu', {
                              active: pathname === subMenuItem.url,
                            })}
                            id={subMenuItem.moduleID}
                          >
                            {subMenuItem.title}
                          </li>
                        );
                      })
                    : null}
                </Collapse>
              ) : null}
            </React.Fragment>
          );
        })}
        <li
          className={cn('sidebar--li')}
          onClick={() => {
            window.FreshworksWidget('open');
          }}
        >
          Help and Support
        </li>
      </ul>
      <a className="sidebar-bottom--link" href={link} target="_blank" rel="noopener noreferrer">
        {/* Register for <br />
        CODE COMBAT */}
        {title}
      </a>
    </nav>
  );
};

const Sidebar = (props) => {
  return (
    <main className="layout--Container">
      <MenuList />
      <div className="main">{props.children}</div>
    </main>
  );
};
export default Sidebar;
