import { ACTIONS } from "../actionTypes/index";

import { merge, cloneDeep, find, pick } from "lodash";

import {
  setOptionsAsObject,
  getOptionsAsObject,
} from "../../utils/LocalStorage";

import { logUserActivity } from "../action/module/module";
const {
  SET_MODULE,
  SET_MODULES,
  SET_MODULES_ERROR,
  SET_CURRENT_MODULE,
  SET_NEXT_MODULE,
  SET_PREV_MODULE,
  SET_ACTIVE_MODULE,
  SET_MODULES_LOADING,
  SET_ACTIVE_MODULE_ID,
} = ACTIONS;
const currentModuleLS = getOptionsAsObject("currentModule");
const activeModuleIdLS = localStorage.getItem("activeModuleId");
const INITIAL_STATE = {
  currentModule: currentModuleLS || {},
  lessonModules: [],
  lessonModulesError: null,
  activeModuleId: activeModuleIdLS || 1,
  activeModule: null,
  areModulesLoading: true,
};
const _module = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MODULES_LOADING: {
      return {
        ...state,
        areModulesLoading: true,
      };
    }
    case SET_MODULES: {
      const lessonModules = action.payload;
      return {
        ...state,
        lessonModules,
        areModulesLoading: false,
      };
    }
    case SET_MODULES_ERROR:
      return {
        ...state,
        lessonModulesError: action.payload,
      };

    case SET_ACTIVE_MODULE: {
      return cloneDeep(merge(state, { activeModule: action.payload }));
      /*  return {
        ...state,
        activeModule: action.payload,
      }; */
    }
    case SET_MODULE: {
      //return cloneDeep(merge(state, { activeModule: action.payload }));

      const { mId } = action.payload;
      const mod = find(state.lessonModules, {
        moduleID: mId,
      });

      // //console.log("mod\n\n\n", mod && mod.displayedCode);
      let curMod = pick(mod, [
        "exerciseID",
        "quizID",
        "module_display_order",
        "structure_type",
        "moduleID",
      ]);

      if (!!mod) {
        setOptionsAsObject(
          "currentModule",
          merge(getOptionsAsObject("currentModule"), {
            ...curMod,
            moduleTitle: mod.module_title,
            lessonId: mod.lessonID,
            courseId: mod.courseID,
          })
        );
        logUserActivity(mod.courseID, mod.lessonID, mId);
      }
      return {
        ...state,
        activeModule: mod ? mod : state.activeModule,
        currentModule: mod
          ? cloneDeep(
              merge(state.currentModule, {
                ...curMod,
                moduleTitle: mod.module_title,
                lessonId: mod.lessonID,
                courseId: mod.courseId,
              })
            )
          : state.currentModule,
      };
    }
    case SET_NEXT_MODULE: {
      const activeModuleId = parseInt(action.payload) + 1;

      localStorage.setItem("activeModuleId", activeModuleId);
      return cloneDeep(merge(state, { activeModuleId }));
    }
    case SET_PREV_MODULE: {
      const activeModuleId = parseInt(action.payload) - 1;

      localStorage.setItem("activeModuleId", activeModuleId);
      return cloneDeep(merge(state, { activeModuleId }));
    }

    case SET_ACTIVE_MODULE_ID: {
      const activeModuleId = parseInt(action.payload);

      localStorage.setItem("activeModuleId", activeModuleId);
      return cloneDeep(merge(state, { activeModuleId }));
    }

    case SET_CURRENT_MODULE: {
      const currentModule = action.payload;
      setOptionsAsObject("currentModule", currentModule);

      return {
        ...state,
        currentModule,
      };
    }
  }
  return state;
};
export default _module;
