//export const URL="https://127.0.0.1:3001/https://ailabbackend.clevered.com/ailabbackend/index.php/api/"
const config = {
  // compilerURL: "https://compiler.clevered.com",
  // compilerKey: "sdkljf56789#KT34_",
  // compilerURL: "http://103.86.177.219:8080/v4/python",
  // compilerURL: "https://95.217.190.200:8080/v4/python",
  compilerURL: process.env.REACT_APP_COMPILER_URL,
  compilerKey: process.env.REACT_APP_COMPILER_KEY,
  baseURL: process.env.REACT_APP_BASE_URL,
};

export default config;
