import BaseService from "./BaseService";

class Service extends BaseService {
  getModules = ({ courseId, lessonId, userId }) => {
    return this.request({
      url: `/api/course-modules-detail/${courseId}/${lessonId}/${userId}`,
    });
  };
  useHint = (body) => {
    return this.request({
      method: 'POST',
      url: `/api/use-hint`,
      body,
    });
  };
  useSolution = (body) => {
    return this.request({
      method: 'POST',
      url: `/api/display-exercise-answer`,
      body,
    });
  };
  logUserActivity = (body) => {
    return this.request({
      url: `/api/user-state-log`,
      method: 'POST',
      body,
    });
  };
  getVideoContent = ({ courseID, lessonID, moduleID }) => {
    return this.request({
      url: `/api/getVideoContent?courseID=${courseID}&lessonID=${lessonID}&moduleID=${moduleID}`,
    });
  };
}
export default Service;
