import { ACTIONS } from "../actionTypes/index";

const {
  SET_COURSE,
  SET_COURSE_ERROR,
  SET_LESSONS,
  SET_LESSONS_ERROR,
  SET_HEADER_COLOR,
} = ACTIONS;

const INITIAL_STATE = {
  courseDetails: [],
  courseDetailsError: null,
  lessons: [],
  lessonsError: null,
  headerColor: "white",
};
const course = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_HEADER_COLOR:
      return {
        ...state,
        headerColor: action.payload,
      };
    case SET_COURSE: {
      const [courseDetails] = action.payload;
      return {
        ...state,
        courseDetails,
      };
    }
    case SET_COURSE_ERROR:
      return {
        ...state,
        courseDetailsError: action.payload,
      };

    case SET_LESSONS:
      return {
        ...state,
        lessons: action.payload,
      };
    case SET_LESSONS_ERROR:
      return {
        ...state,
        lessonsError: action.payload,
      };

    default:
      return state;
  }
};
export default course;
