export const SERVER_ERROR_CODES = {
  NO_CONNECTION: "no.connection",
  CONNECTION_ABORTED: "ECONNABORTED",
  INTERNAL_SERVER_ERROR: "internal.server.error",
  SERVER_IS_NOT_AVAILABLE: "server.is.not.available",
  NO_CONNECTION_OR_SERVER_IS_NOT_AVAILABLE:
    "no.connection.or.server.is.not.available",

  INVALID_TOKEN: "token.invalid",
  INVALID_PASSWORD: "invalid.password",
  INVALID_LOGIN: "invalid.login",
  INVALID_COMPANY_ID: "invalid.company.id",

  NO_PHYSICIAN_FOUND: "physician.not.found",
  NO_PATIENT_INFO_FOUND: "patient.info.not.found",
  NO_PATIENTS_FOUND: "no.associated.patients.found",

  NO_PHONE_FOR_REGISTRATION: "no.phone.for.registration",
  NO_EMAIL_FOR_REGISTRATION: "no.email.for.registration",
  NO_PHONE_AND_EMAIL_FOR_REGISTRATION: "no.phone.and.email.for.registration",

  UNAUTHORIZED: "auth.authorized",
  BAD_CREDENTIALS: "auth.bad.credentials",
  EXPIRED_CREDENTIALS: "auth.credentials.expired",

  ACCOUNT_LOCKED: "auth.account.locked",
  ACCOUNT_INACTIVE: "auth.account.inactive",
  ACCOUNT_NOT_FOUND: "auth.account.not.found",

  COMING_SOON: "coming.soon",
};

export const ROUTE_MAPPING = {
  1: 'intro',
  2: 'video',
  3: 'quiz',
  4: 'lab',
  5: 'document',
  6: 'scratch-pad',
  7: 'web-pad',
};

export const ROLES = {
  1: "SUPER_ADMIN",
  2: "ADMIN",
  3: "STUDENT",
  4: "TEACHER",
};
